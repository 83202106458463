


























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { STATE_LIST } from "@/constants/ProfileConstants";
import PhoneInput from "@/components/util/PhoneInput.vue";
import CreateSublimeOfficeRequest from "@/dto/sublime/office/CreateSublimeOfficeRequest";
import { namespace } from "vuex-class";
import AdminService from "@/services/AdminService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";


const AppModule = namespace("App");

@Component({
    components: {PhoneInput}
})
export default class SublimeOfficeCreationModal extends Vue{
    private states = STATE_LIST;

    private request = new CreateSublimeOfficeRequest();

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private message = "";

    private successful = false;

    @Prop()
    private onSuccess!: () => void

    zipCodeHandler() {
        if (this.request.address.zipCode) {
            if (this.request.address.zipCode.length > 5) {
                this.request.address.zipCode = this.request.address.zipCode.substr(0, 5);
            }
        }
    }

    private save(): void {
        this.$validator.validateAll().then(
            isValid => {
                if (isValid) {
                    if (this.request.phone!.length < 7) {
                        this.request.phone = null;
                    }
                    this.startLoading();
                    AdminService.createSublimeOffice(this.request).then(
                        () => this.processResp(),
                        err => {
                            this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                            this.successful = false;
                            this.stopLoading();
                        }
                    )
                }
            }
        )
    }

    processResp() {
        this.successful = true;
        this.stopLoading();
        this.$modal.hideAll();
        this.onSuccess();
    }

};
